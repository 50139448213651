import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import Map from "@arcgis/core/Map"
import MapView from "@arcgis/core/views/MapView";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer"
import EsriConfig from "@arcgis/core/config";
import PopupTemplate from "@arcgis/core/PopupTemplate"
import CustomContent from "@arcgis/core/popup/content/CustomContent"
import BasemapToggle from "@arcgis/core/widgets/BasemapToggle";
import Query from "@arcgis/core/rest/support/Query";
import {UniqueValueRenderer} from "@arcgis/core/rasterRenderers";
import {SimpleFillSymbol} from "@arcgis/core/symbols";

function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export class ArcGISFullMapBuilder {

    constructor(id, dotNetObj, geoFenceURL, ahjLatitude, ahjLongitude, accessToken, featureLayerURL) {
        const SQL_dictionary={
            "Compliance Status (All)":"premises_deficiency_status_code LIKE '%'",
            "Compliant":"premises_deficiency_status_code = 'COMPLIANT'",
            "Deficient":"premises_deficiency_status_code = 'DEFICIENT'",
            "Unknown":"premises_deficiency_status_code = 'UNKNOWN'",
            "Overdue Status (All)":"(premises_is_overdue = 'True' or premises_is_overdue = 'False')",
            "Overdue":"premises_is_overdue = 'True'",
            "Not Overdue":"premises_is_overdue = 'False'",
        };

        // currently everything is hard coded for testing purposes
        EsriConfig.apiKey = "AAPK611fdef280214bc390fbc948069f577euQ6HQ-y77_8KRFu1ur1d1e9K1o0qopRpCAGnZoij5_CmG0ZOIulN0KByERdimaXr";

        EsriConfig.request.interceptors.push({
            // set the `urls` property to the URL of the FeatureLayer so that this
            // interceptor only applies to requests made to the FeatureLayer URL
            urls: featureLayerURL,
            // use the BeforeInterceptorCallback to add token to query
            before: function(params) {
                params.requestOptions.query = params.requestOptions.query || {};
                params.requestOptions.query.token = accessToken;
            }
        });

        const map = new Map({
            basemap: "streets-navigation-vector"
        });

        const view = new MapView({
            container: id, // div element
            map: map,
            center: [parseFloat(ahjLongitude), parseFloat(ahjLatitude)],
            zoom: 12,
            popup: {
                dockEnabled: true,
                dockOptions: {
                    buttonEnabled: false,
                    breakpoint: false,
                },
            }
        });

        const basemapToggle = new BasemapToggle({
            view: view,  // The view that provides access to the map's "streets-vector" basemap
            nextBasemap: "hybrid"  // Allows for toggling to the "hybrid" basemap
        });

        view.ui.add(basemapToggle, "bottom-left");

        const mapLayer = new FeatureLayer({
            url: featureLayerURL
        });

        const customContent = new CustomContent({
            outFields: ["*"],
            creator: (event) => {
                const name = `${event.graphic.attributes["premises_full_address_block"]}`;
                const link = `<a href=${window.location.origin}/premises/show/${event.graphic.attributes["premises_id"]}><b>View</b></a>`;
                const deficiencyCode = `${event.graphic.attributes["premises_deficiency_status_code"]}`
                const isOverdue = `${event.graphic.attributes["premises_is_overdue"]}` 

                return `<div class="container-fluid p-2">
                            <div class="row">
                                <div class="col">Address</div>
                                <div class="col">${name}</div>
                            </div>
                            <div class="row">
                                <div class="col">Compliance Status</div>
                                <div class="col">${deficiencyCode}</div>
                            </div>
                            <div class="row">
                                <div class="col">Is Overdue</div>
                                <div class="col">${isOverdue}</div>
                            </div>
                            <div class="row">
                                <div class="col">Link</div>
                                <div class="col">${link}</div>
                        </div>
                    </div>`
            },
        });

        mapLayer.popupTemplate = new PopupTemplate({
            outFields: ["*"],
            title: "{premises_name}",
            content: [customContent],
        });

        const complianceStatusSQL = ["Compliance Status (All)", "Compliant",  "Deficient", "Unknown"];
        const premisesOverdueSQL = ["Overdue Status (All)", "Overdue", "Not Overdue"]
        let whereClause = SQL_dictionary[complianceStatusSQL[0]] + "and" + SQL_dictionary[premisesOverdueSQL[0]];

        const filterDiv = document.createElement("div");

        const complianceStatusSelect = document.createElement("select");
        complianceStatusSelect.setAttribute("id", "complianceStatusSelectDiv");
        complianceStatusSelect.setAttribute("class", "esri-widget esri-select");
        complianceStatusSelect.setAttribute("style", "width: 200px; font-family: 'Avenir Next'; font-size: 1em");
        complianceStatusSQL.forEach(function(query){
            let option = document.createElement("option");
            option.innerHTML = query;
            option.value = query;
            complianceStatusSelect.appendChild(option);
        });

        const premisesOverdueSelect = document.createElement("select");
        premisesOverdueSelect.setAttribute("id", "premisesOverdueSelectDiv");
        premisesOverdueSelect.setAttribute("class", "esri-widget esri-select");
        premisesOverdueSelect.setAttribute("style", "width: 200px; font-family: 'Avenir Next'; font-size: 1em");
        premisesOverdueSQL.forEach(function(query){
            let option = document.createElement("option");
            option.innerHTML = query;
            option.value = query;
            premisesOverdueSelect.appendChild(option);
        });

        filterDiv.appendChild(complianceStatusSelect);
        filterDiv.appendChild(premisesOverdueSelect);
        view.ui.add(filterDiv, "bottom-right")

        // The way the whereClause is built now is fine because it is just two options and no need to over complicate things.
        // If more filters are added in the future then a more intelligent way should be considered.
        complianceStatusSelect.addEventListener('change', (event) => {
            var otherVal = document.getElementById("premisesOverdueSelectDiv").value;
            whereClause = SQL_dictionary[event.target.value] + " AND " + SQL_dictionary[otherVal];
            console.log(whereClause)
            view.closePopup();
            mapLayer.definitionExpression = whereClause;
        });

        premisesOverdueSelect.addEventListener('change', (event) => {
            var otherVal = document.getElementById("complianceStatusSelectDiv").value;
            whereClause = SQL_dictionary[event.target.value] + " AND " + SQL_dictionary[otherVal];
            console.log(whereClause)
            view.closePopup();
            mapLayer.definitionExpression = whereClause;
        });

        mapLayer.definitionExpression = whereClause;

        let geoJsonLayer = null;
        if (geoFenceURL !== null)
        {
            geoJsonLayer = new GeoJSONLayer({
                url: geoFenceURL,
            });
            
            void geoJsonLayer.when(function () {
                if (geoJsonLayer.getField('fill')) {
                    const query = new Query();
                    query.returnDistinctValues = true;
                    query.returnGeometry = false;
                    query.outFields = ['fill', 'stroke', 'fill_opacity', 'stroke_opacity', 'stroke_width'];
                    geoJsonLayer.queryFeatures(query).then(function(results) {
                        const renderer = new UniqueValueRenderer();
                        renderer.field = 'fill';
                        renderer.defaultSymbol = new SimpleFillSymbol();
                        results.features.forEach((element, index) => {
                            let rgb = hexToRgb(element.attributes['fill']);
                            console.log("index:" + index)
                            console.log(element.attributes['fill']) 
                            let strokeRgb = hexToRgb(element.attributes['stroke']);
                            
                            let rgbaText = "rgba(3, 35, 61, .5)";
                            let rgbaStrokeText = "rgba(3, 35, 61, 1)";
                            if(rgb !== null){
                                rgbaText = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${element.attributes['fill_opacity']})`;
                            }
                            if(strokeRgb !== null){
                                rgbaStrokeText = `rgba(${strokeRgb.r}, ${strokeRgb.g}, ${strokeRgb.b}, ${element.attributes['stroke_opacity']})`;
                            }
                            renderer.addUniqueValueInfo({
                                value: element.attributes['fill'],
                                symbol: {
                                    type: "simple-fill",
                                    color: rgbaText,
                                    outline: {
                                        width: element.attributes['stroke_width'],
                                        color: rgbaStrokeText
                                    }
                                }
                            });
                        }); 
                        geoJsonLayer.renderer = renderer;
                    }); 
                } else {
                    geoJsonLayer.renderer = {
                        type: "simple",
                        symbol: {
                            type: "simple-fill",
                            color: "rgba(3, 35, 61, .5)",
                            outline: {
                                width: 3,
                                color: "#01223d"
                            }
                        }
                    };
                }
            }, function(error) {
                console.log("MAP FAILURE", error)
            });
        }

        map.add(mapLayer, 0);
        if (geoFenceURL !== null)
            map.add(geoJsonLayer,0);
    }
}